.FormGroup {
	padding: 0;
	border-style: none;
	will-change: opacity, transform;
	box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
	border-radius: 4px;
    max-width: 600px;
    background-color: #313a46;
    color: White;
}

.FormRow {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	margin-left: 15px;
	border-top: 1px solid #313a46;
}

.StripeElement--webkit-autofill {
	background: transparent !important;
}

.StripeElement {
	width: 100%;
	padding: 11px 15px 11px 0;
}

.App {
	margin-left: 25%;
	margin-top: 5%;
	margin-right: 25%;
}

h1,
h3 {
	text-align: center;
}