.btn-primary {
  color: #313a46 !important;
}

#incoming-calls {
  position: fixed;
  right: 0;
  width: 320px;
  z-index: 10000;
  padding: 10px;
}

#video-call-screen {
  width: 100%;
  height: 100vh;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.call-hangup-button {
  font-size: 70px;
  line-height: 60px;
  border: 0 none;
  position: fixed;
  bottom: 20px;
  right: calc(50vw - 65px);
  width: 110px;
  padding: 20px;
  height: 110px;
  border-radius: 50%;
  z-index: 100;
}

.room {
  position: absolute; 
  z-index: 100;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.local-participant {
  z-index: 3;
  position: absolute;
  right: 0;
  top: 0;
  width: 320px;
  padding: 10px;
}

.remote-participant {
  position: absolute;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.remote-participant video {
  position: relative;
  width: 100%;
  height: 100vh; 
  background: black;
  display: block;
  outline: none;
}




.popup-backdrop {
  position: fixed;
  z-index: 1000000000000;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.popup-backdrop .popup {
  box-shadow: 0 0 35px 0 rgba(154,161,171,.15);
  border-radius: .25rem;
  padding: 18px;
  background-color: white;
  width: 400px;
}

@media screen and (max-width: 768px) {
  .leftside-menu {
    z-index: 1000000000 !important;
  }
}

.empty-avatar {
  border: 10px solid rgba(0,0,0, 0.4);
  padding: 50px 10px;
  margin-bottom: 30px;
}